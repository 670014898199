import http from '@/store/http';
import umiRequest from 'umi-request';

export const uploadFile = ({filename, path, file}) => {
    return new Promise((resolve, reject) => {
        http
            .request('/operate/file/upload', 'POST_JSON', {filename, path})
            .then(async (res: any) => {
                if (res.code === 1) {
                    const {uploadUrl, downloadUrl, contentType} = res.info;
                    await umiRequest.put(uploadUrl, {
                        headers: {'Content-Type': contentType},
                        data: file,
                    });
                    resolve(downloadUrl);
                } else {
                    reject(new Error('Upload failed.'));
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export function complaintSum() {
    return http.request('/operate/complaint/sum', 'GET');
}
